#tournament-conception-modal {
	.game-card {
		display: inline-block;
		min-height: 250px;
	}

	.game-card, .conception-custom-theme{
		padding-bottom: 20px;
	}

	.conception-theme {
		height: 100%;
	}

	.conception-theme, .conception-custom-theme {
		width: 300px !important;
	}

	.themes-list {
		max-width: 1400px;
	}
}

body.desktop #tournament-conception-modal {
	.conception-custom-theme {
		min-height: 250px;
	}
}
