#terms-header {
  height: 75px;
  background: transparent;

  img {
    height: 50px;
  }

  button {
    border-radius: 4px !important;
  }
}
