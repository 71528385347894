@import "./bootstrap/_variables";

// VARIABLES
$team-colors: (
        $danger,
        $warning,
        $info,
        $success,
);

$team-bg-secondary: (
        lighten($danger, 33%),
        lighten($warning, 35%),
        lighten($info, 28%),
        lighten($success, 45%),
);


// STYLES
@for $index from 0 to length($team-colors) {
  $index: $index+1;
  $color: nth($team-colors, $index);

  .bg-team-secondary-#{$index} {
    background-color: nth($team-bg-secondary, $index);
  }

  .text-team-#{$index} {
    color: $color;
  }

  .bg-team-#{$index} {
    font-size: 100%;
    background-color: $color;
  }

  .border-team-#{$index} {
    border-color: $color;
  }

  .badge-team-#{$index} {
    background-color: $color;
    color: white;
  }

  .btn-team-#{$index} {
    background-color: $color;
    color: white;

    &:hover{
      background-color: darken($color, 10%) !important;
    }
  }
}

.badge-team-, .badge-team-undefined, .badge-team-null {
  font-size: 100%;

  background-color: $light;
  color: $primary;
  border: 1px solid lightgrey;
  border-radius: 4px;
}

.text-ranking-1{
  color: #F7D000 !important;
}

.text-ranking-2{
  color: #C9C9C9 !important;
}

.text-ranking-3{
  color: #CD8544 !important;
}

.text-ranking-4{
  color: #F2F1E1 !important;
}